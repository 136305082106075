import React from 'react'
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import {StyledPasswordModal} from "./style"
import { verificationDomain } from "../../../redux/orderSlice";
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';

const PasswordModal = ({setShowButton}) => {
    const encryptString = (text, secretKey) => {
        return CryptoJS.AES.encrypt(text, secretKey).toString();
      };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const initialValues = { 
        password: ''
    };
    const validationSchema = yup.object({
        password: yup.string().required('Please enter password.'),
    });
    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
    } = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const data = {
              password: values?.password,
              type: "Admin"
            };
            dispatch(verificationDomain(data))
              .unwrap()
              .then((res) => {
                if (res.status === 1) {
                  toast.success(`${res?.message}`, {
                    className: "toast-message",
                  });
                  setShowButton(false);
                  sessionStorage.setItem("loginUser", encryptString(res?.data?.type, "hcDkYrS54"));
                  navigate('/landinglist');
                } else {
                  toast.error(`${res?.message}`, {
                    className: "toast-message",
                  });
                }
                // setShowButton(false);
              });
        },
    });
    return (
        <StyledPasswordModal>
            <form onSubmit={handleSubmit}>
            <div className="card-body col-lg-6 password-section">
              <div className="form-group mt-3">
                <div className="form-label-group">
                  <label htmlFor="inputPassword">Password</label>
                  <input
                    type="password"
                    id="inputPassword"
                    name="password"
                    className="form-control"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <p style={{ color: "red" }}>{errors.password}</p>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-block mt-4 w-100 submit-btn"
              >
                Login
              </button>
            </div>
            </form>
        </StyledPasswordModal>
    )
}

export default PasswordModal