import React from 'react'
import { DomainCard } from './style'
import { useNavigate } from "react-router-dom";

const LandingCard = ({ landingSiteName, imagePath,siteId }) => {
    const navigate = useNavigate();
    const handleDomainList = (e) => {
        e.preventDefault();
        navigate(`/domain-list/${siteId}`);
      };
    return (
        <DomainCard>
        
                <div className='landing-card'onClick={handleDomainList}>
                    <div className="card" style={{ width: "18rem" }}>
                        <img class="card-img-top" src={imagePath} alt="Card image cap" />
                        <div className="card-body">
                            <h5 className="card-title text-center">{landingSiteName}</h5>
                        </div>
                    </div>
                </div>
         
        </DomainCard>
    )
}

export default LandingCard
