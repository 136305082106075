import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { StyledDataTable, SearchField } from "./style";
const ReactDataTable = ({ data, customData, customColumns, setButtonData, setDomainList }) => {
  const [filterData, setFilterData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const isLoading = useSelector((state) => state?.order?.domainLoading);
  let columns;
  let Data = [];

  useEffect(()=> {
    setFilterData(data)
  },[data])

  if (
    data &&
    !customData &&
    typeof data !== "undefined" &&
    typeof data !== null &&
    data?.length !== 0
  ) {
    Data = filterData?.map((item, index) => {
      return {
        id: item?.id,
        count: item?.count,
        domain: item?.domain,
        domainName: item?.domainName,
        email: item?.email,
        loginUrl: item?.loginUrl,
        mailCount: item?.mailCount,
        button1: item?.button1,
        parentSiteType: item?.parentSiteType,
        button2: item?.button2,
        button3: item?.button3,
        cookie_status: item?.cookie_status,
        cookie_enable: item?.cookie_enable,
      };
    });
  }

  
  const handleSearch = (searchContent)=> {
    const searchResult = data?.filter((item) => item?.domainName?.includes(searchContent));
    // setDomainList(searchResult);
    setFilterData(searchResult);
    setSearchText(searchContent);
  }
  if(!customColumns) {
    columns = [
      {
        name: "S.No.",
        selector: (row) => row.id,
        sortable: true,
      },
      {
        name: "New Orders Count",
        selector: (row) => row.count,
        sortable: true,
      },
      {
        name: "Mail Count",
        selector: (row) => row.mailCount,
        sortable: true,
      },
      {
        name: "Login url",
        sortable: true,
        selector: (row) =>
          data?.length > 0 ? (
            <span>
              <a
                href={row?.loginUrl}
                target="_blank"
                style={{
                  border: "1px solid #18f",
                  borderRadius: "5px",
                  padding: " 0 5px",
                  minWidth: "80px",
                  display: "inline-block",
                  textAlign: "center",
                  height: "40px",
                  lineHeight: "40px",
                  color: "#111",
                  margin: "5px",
                  textDecoration: "none",
                }}
                className="blue-border"
              >
                Click here
              </a>
            </span>
          ) : null,
      },
    ];
  } else {
    columns = customColumns;
  }

  return (
    <>
      <SearchField>
          <input
            type="search"
            id="search_field"
            className="search-bar form-control"
            value={searchText}
            onChange={(e) => handleSearch(e.target.value)}
            // onChange={(e) => setFilterText(e.target.value)}
            name="search_field"
            placeholder="Search"
          />
        </SearchField>
      <StyledDataTable>
        <DataTable
          className="uspassport-custom-tbl"
          selectableRows={false}
          columns={columns}
          data={Data?.length ? Data : [{}]}
          progressPending={isLoading}
          fixedHeader={true}
          fixedHeaderScrollHeight="550px"
          noDataComponent="No records found"
          pagination={Data?.length ? true : false}
        />
      </StyledDataTable>
      {!Data?.length && isLoading === false && (
        <p style={{ textAlign: "center", color: "red" }}>
          {"No data available in table"}
        </p>
      )}
    </>
  );
};

export default ReactDataTable;
