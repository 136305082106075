import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  StyledContainer,
  StyledPageTitle,
  StyledOrderBlock,
} from "./style";
import ReactDataTable from "../../../Components/ReactDataTable/ReactDataTable";
import Modal from "../../../Components/Modal/Modal";
import ButtonModal from "../ButtonModal";
import { getAllDomainList } from "../../../redux/orderSlice";
import { toast } from "react-toastify";

const DomainList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  if(!sessionStorage.getItem("loginUser")){
      navigate("/admin");
    }

  const domainListData = useSelector((state) => state?.order?.domainDetails);
  const [domainList, setDomainList] = useState([]);
  const [buttonData, setButtonData] = useState({})
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [showButton, setShowButton] = useState(false); 
  useEffect(() => {
    setDomainList(domainListData);
  }, [domainListData])
  const handleClose = () => setShowButton(false);
  const handleShow = (orderId) => {
    const result = domainList?.filter((item) => item?.id == orderId.id);
    setButtonData(result);
    setShowButton(true);
  };

  useEffect(() => {
    dispatch(getAllDomainList())
      .unwrap()
      .then((res) => {
        if (res.status === 401) {
          toast.error(`${res?.message}`, {
            className: "toast-message",
          });
          localStorage.removeItem("user");
        }
      });
    document.title = "Domain List | My Admin Panel";
  }, []);

  const tableData = domainList;

  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Domain Name",
      selector: (row) => row?.domainName,
      sortable: true,
    },
    {
      name: "Button 1",
      selector: (row) => row?.button1,
      sortable: true,
    },
    {
      name: "Button 2",
      selector: (row) => row?.button2,
      sortable: true,
    },
    {
      name: "Parent Site",
      selector: (row) => row?.parentSiteType,
      sortable: true
    },
    {
      name: "Action",
      width: "18rem",
      selector: (row) =>
        domainListData?.length !== 0 ? (
          <span>
            <Link
              to="#"
              onClick={() => handleShow({ id: row?.id })}
              style={{
                textDecoration: "none",
                border: "1px solid #e92a11",
                borderRadius: "5px",
                padding: " 0 5px",
                minWidth: "80px",
                display: "inline-block",
                textAlign: "center",
                height: "40px",
                lineHeight: "40px",
                color: " #e92a11",
                margin: "5px",
              }}
            >
              Change Button
            </Link>{" "}
          </span>
        ) : (
          " "
        ),
    },
  ];

  return (
    <StyledContainer>
      <StyledPageTitle>
        <h1>Domain List</h1>
      </StyledPageTitle>
      <StyledOrderBlock>
        <div className="table-block table-responsive">
          <ReactDataTable
            data={tableData}
            customColumns={columns}
            setPage={setPage}
            setLimit={setLimit}
            orderName="domainlist"
            setButtonData={setButtonData}
            setDomainList={setDomainList}
          />
          <Modal
            show={showButton}
            close={setShowButton}
            size={"xl"}
            modalHeadTitle={"Choose button from given options:"}
            showFooter={false}
          >
            <ButtonModal setShowButton={setShowButton} orderDetails={buttonData} />
          </Modal>
        </div>
      </StyledOrderBlock>
    </StyledContainer>
  );
};

export default DomainList;


