import styled from "styled-components";

export const StyleLayout = styled.div`
  & a {
    text-decoration: none;
    border: 1px solid #18f;
    borderradius: 5px;
    padding: 6px 5px;
    minwidth: 80px;
    border-radius: 5px;
    display: inline-block;
    textalign: center;
    height: 40px;
    lineheight: 40px;
    // color: #111;
    color: #18f;
    margin: 5px;
    textdecoration: none;
  }

  .card-body {
    flex: 1 1 auto;
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    color: var(--bs-card-color);
    /* color: black; */
    border: 2px solid black;
    border-radius: 5px;
    text-align: center;
  }
`;
