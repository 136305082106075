import ApiService from "./ApiService";

export default class AuthService {
  static login = (user) =>
    ApiService.post(
      "/admin/admin-login",
      user,
      { baseURL: `https://api.admin-us.online/api/`},

    );
}
