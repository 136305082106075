import React, { useState } from "react";
import User from "../../../../assests/images/user.png";
import {
  StyledHeader,
  StyledHeaderRight,
  StyledLogo,
  StyledAdminHeaderRight,
} from "./style";
import { FaSignOutAlt } from "react-icons/fa";
import { FiAlignJustify } from "react-icons/fi";
import { FaHome } from "react-icons/fa";
import { logout } from "../../../../redux/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { toggleSidebar } from "../../../../redux/orderSlice";
import Dropdown from "react-bootstrap/Dropdown";
import jwt from "jwt-decode";
import AdminLogo from "../../../../assests/img/edocs-logo.png";
import Modal from "../../../../Components/Modal/Modal";
import PasswordModal from "../../../../pages/Admin/PasswordModal/PasswordModal"


const AdminNavbar = () => {
  const [showButton, setShowButton] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem("user");
  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("user");
    sessionStorage.removeItem("loginUser")
    dispatch(logout());
  };

  const handleShow = (orderId) => {
    setShowButton(true);
  };

  const handleDomainList = (e) => {
    e.preventDefault();
    navigate('/domainlist');
  };
  const handleLandingList = (e) => {
    e.preventDefault();
    navigate('/landinglist');
  };
  const handleHomePage = (e) => {
    e.preventDefault();
    navigate('/admin');
  };
  let userDetails;

  if (token !== null) {
    const decodedToken = jwt(token);
    userDetails = decodedToken?.user;
  }



  return (
    <StyledHeader>
      <div className="toggle-button-box">
        <button
          type="button"
          className="toggle-button"
          onClick={() => {
            dispatch(toggleSidebar());
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
      <StyledLogo>
        <img src={AdminLogo} alt="Logo" />
      </StyledLogo>
      <StyledHeaderRight>
        <StyledAdminHeaderRight>
          <ul>
            <li className="dropdown">
              <span className="admin-name">{`Welcome ${userDetails?.name}`}</span>
              <div className="user-top">
                <span className="online"></span>
                <span className="user-img">
                  <img src={User} alt="user" />
                </span>
              </div>
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="dropdown-toggle"
                >
                  {" "}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#" onClick={handleLogout}>
                    {" "}
                    <FaSignOutAlt className="logout-icons" />
                    Logout
                  </Dropdown.Item>
                  {typeof location?.pathname !== "undefined" && location?.pathname != "/admin" && (
                    <Dropdown.Item href="#" onClick={handleHomePage}>
                      {" "}
                      <FaHome className="logout-icons" />
                      Home Page
                    </Dropdown.Item>
                  )}
                  {typeof userDetails !== "undefined" && userDetails?.type == 'Admin' && (
                    <>
                      {/* {typeof location?.pathname !== "undefined" && location?.pathname != "/domainlist" && (
                        <Dropdown.Item href="#" onClick={handleDomainList}>
                          {" "}
                          <FiAlignJustify className="logout-icons" />
                          Domain List
                        </Dropdown.Item>
                      )} */}

                      {typeof location?.pathname !== "undefined" && location?.pathname != "/domainlist" && !sessionStorage.getItem("loginUser") ? (
                        <Dropdown.Item href="#" onClick={handleShow}>
                          {/* <Dropdown.Item href="#" onClick={handleLandingList}> */}
                          {" "}
                          <FiAlignJustify className="logout-icons" />
                          Landing Buttons
                        </Dropdown.Item>
                      ) :
                        (
                          <Dropdown.Item href="#" onClick={handleLandingList}>
                            {" "}
                            <FaHome className="logout-icons" />
                            Landing Buttons
                          </Dropdown.Item>
                        )

                      }

                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
          <Modal
            show={showButton}
            close={setShowButton}
            size={"xl"}
            modalHeadTitle={"Please Enter Password"}
            showFooter={false}
          >
            <PasswordModal setShowButton={setShowButton} />

          </Modal>
        </StyledAdminHeaderRight>
      </StyledHeaderRight>
    </StyledHeader>
  );
};

export default AdminNavbar;
